import styled from 'styled-components';

export const Container = styled.a`
  display: block;
  margin: 0 auto;
  max-width: 360px;

  img {
    width: 100%;
  }
`;
